



































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
  },
})
export default class OpenCallExtractionsForm extends Vue {
  @Prop()
  public provider!: any;

  private programmes: any = [];
  private filters: any = [];

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const prog = (await this.$service.providers.extractionProgramms.fetchItemsAsync()).items;
    this.programmes = prog.map((i: any) => i.title)

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      programmes: [],
      startDate: null,
      endDate: null
    }
  }
}
